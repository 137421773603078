function MainPage() {
  return (
    <section>
      <h1>ML aided Melody Generation and Scale detection</h1>
      <img src={"/meloaid.jpg"} alt="logo" />
    </section>
  );
}

export default MainPage;
